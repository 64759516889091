import React, { Component } from "react"
import { Helmet } from "react-helmet"
import { ContactForm, Layout } from "../../../components"
import Screen from "../../../images/reference/ldf/ldf.png"
import Logo from "../../../images/reference/ldf/logoldf.png"
import Sideimg from "../../../images/reference/ldf/screen-1.png"
import ReferenceImageText from "../../../components/reference-detail/reference-image-text"
import ReferenceHeader from "../../../components/reference-detail/reference-header"

class LDFMendelu extends Component {
  constructor({ props }) {
    super(props)
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <title>Reference na nový web pro LDF MENDELU | igloonet</title>
          <meta
            name="description"
            content="Marketing, vývoj a hosting pod střechou jedné firmy. Programujeme moderní eshopy, poskytujeme prvotřídní hostingové služby a tvoříme marketingové strategie na míru."
          />
        </Helmet>

        <ReferenceHeader
          companyName="MENDELU"
          heading="Reference na nový web pro LDF MENDELU"
          date="2013"
        />

        <ReferenceImageText
          right
          img={Screen}
          eager
          alt=""
          logo={Logo}
          logoAlt="logo společnosti LDF MENDELU"
        >
          Lesnická a dřevařská fakulta je jednou z pěti fakult Mendelovy
          univerzity v Brně a zároveň její vlajkovou lodí na poli výzkumu a
          vývoje krajiny, ekologie a zpracování dřeva. Každý rok přijímá 700
          nových uchazečů.
        </ReferenceImageText>

        <ReferenceImageText>
          <h2>Zadání</h2>
          <p>
            Vytvořit unikátní webovou stránku pro podporu kampaně na nábor
            nových studentů.
          </p>

          <div className="py-5">
            <h2>Rozsah projektu</h2>
            <p>
              Vytvoření responzivní microsite s moderním designem, která má
              zaujmout mladé lidi ke studiu na Vysoké škole. Navrhli jsme a
              vytvořili responzivní šablonu. Při realizaci jsme použili
              frontendový framework Foundation. Web jsme doplnili o moderní
              prvky včetně parallax efektu pomocí CSS3 a jQuery pluginů.
            </p>
          </div>
        </ReferenceImageText>

        <ReferenceImageText halfImgSize img={Sideimg} alt="">
          <h2>Historie spolupráce</h2>
          <p>
            S LDF úspěšně spolupracujeme v oblasti online marketingu již od roku
            2012. Na jaře 2013 jsme vyvinuli zmíněný web. I přes stále se
            snižující počet absolventů středních škol se snažíme jít proti
            trendu a počet přihlášek zvyšovat. A to se nám daří plnit i díky
            podpoře našich vývojářů.
          </p>
        </ReferenceImageText>

        <ContactForm contact="sobol" />
      </Layout>
    )
  }
}

export default LDFMendelu
